.calculator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 300px;
}

.display {
  width: 100%;
  margin-bottom: 10px;
}

.display input {
  width: 100%;
  padding: 10px;
  font-size: 20px;
  text-align: right;
  border: none;
  border-bottom: 1px solid #ccc;
  margin-bottom: 5px;
}

.result {
  font-size: 24px;
  color: black;
  padding: 10px;
  background-color: #f0f0f0; /* Give it a background color */
  border: 1px solid #ccc;
  margin-top: 10px; /* Add margin to prevent overlapping */
  text-align: right; /* Align the text to the right */
  min-height: 30px; /* Ensure enough height for result to be visible */
}

.buttons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

button {
  padding: 20px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

button:active {
  background-color: #003f7f;
}
